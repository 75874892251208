import { CategoryChip } from '@libs/entity-lib';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './visitor-guide.actions';

export interface State {
  loading: boolean;
  categories: CategoryChip[] | null;
  error: any | null;
}

export const initialState: State = {
  loading: false,
  categories: [],
  error: null
};

export const reducer = createReducer(
  initialState,
  on(fromActions.fetchVisitorGuide, (state) => ({ ...state, loading: true })),
  on(fromActions.fetchVisitorGuideSuccefully, (state, { categoryChips }) => ({ ...state, categories: categoryChips, loading: false })),
  on(fromActions.fetchVisitorGuideFailed, (state, { error }) => ({ ...state, error, loading: false })),

  on(fromActions.registerVisitorGuide, (state) => ({ ...state, loading: true })),
  on(fromActions.registerVisitorGuideSuccefully, (state, { categoryChips }) => ({ ...state, categories: categoryChips, loading: false })),
  on(fromActions.registerVisitorGuideFailed, (state, { error }) => ({ ...state, error, loading: false })),
);
