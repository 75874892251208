import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  ApplicationConfig,
  importProvidersFrom,
  provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import {
  provideRouter,
  RouteReuseStrategy,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
} from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { metaReducers, reducers } from '@lessor/store/index';
import { AuthLibModule } from '@libs/auth-lib';
import { CrashlyticsService } from '@libs/crashlytics';
import { Canal } from '@libs/entity-lib';
import {
  commonEnvironment,
  lessorEnvironment,
} from '@libs/shared/util-environments';
import { appRoutes } from './app-routes';
import { CoreModule } from './core/core.module';
import { InterceptorProvider } from './providers/interceptor/interceptor';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { ProfilelLessorStateModule } from '@libs/settings-state';
import { VibrationService } from '@libs/util-services';
import { provideIonicAngular } from '@ionic/angular/standalone';
import { provideServiceWorker } from '@angular/service-worker';
import { NotificationListenersService } from '@libs/notifications';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom([
      StoreModule.forRoot(reducers, { metaReducers }),
      IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }),
      StoreDevtoolsModule.instrument({
        maxAge: 25,
        logOnly: lessorEnvironment.production,
      }),
      EffectsModule.forRoot([]),
      AuthLibModule,
      CoreModule,
      ProfilelLessorStateModule,
    ]),
    provideFirebaseApp(() =>
      initializeApp(commonEnvironment.apps[Canal.SPORT_CENTER].firebaseConfig)
    ),
    provideFirestore(() => getFirestore()),
    provideIonicAngular({ mode: 'ios' }),
    provideRouter(
      appRoutes,
      withComponentInputBinding(),
      withEnabledBlockingInitialNavigation()
    ),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorProvider, multi: true },
    {
      provide: FIREBASE_OPTIONS,
      useValue: commonEnvironment.apps[Canal.SPORT_CENTER].firebaseConfig,
    },
    CrashlyticsService,
    VibrationService,
    NotificationListenersService,
    provideExperimentalZonelessChangeDetection(),
    provideServiceWorker(lessorEnvironment.serviceWorker, {
      enabled: lessorEnvironment.enabledSeviceWorker,
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};
