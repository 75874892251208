
import { createReducer, on } from '@ngrx/store';
import { ConfigOpeningHours } from '@libs/entity-lib';
import * as fromOpeningHours from './opening-hours.actions';

export interface State {
  loading: boolean;
  configOpeningHours: ConfigOpeningHours | null;
  error: any | null;
}

export const initialState: State = {
  loading: false,
  configOpeningHours: null,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(fromOpeningHours.fetchConfigOpeningHoursFailed, (state, { error }) =>
    ({ ...state, error })),
  on(fromOpeningHours.fetchConfigOpeningHoursSuccessfuly, (state, { configOpeningHours }) =>
    ({ ...state, configOpeningHours, error: null })),
  on(fromOpeningHours.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  }))
);
