
import { createReducer, on } from '@ngrx/store';
import { Sport } from '@libs/entity-lib';
import * as fromUtil from './util.actions';

export interface State {
    loading: boolean;
    typesCourt: string[];
    statusCourt: string[];
    sportsCourt: Sport[];
    error: any | null;
}

export const initialState: State = {
    loading: false,
    statusCourt: [],
    typesCourt: [],
    sportsCourt: [],
    error: null
};

export const reducer = createReducer(
  initialState,
  on(fromUtil.setStatusCourts, (state, { statusCourt }) => ({ ...state, statusCourt })),
  on(fromUtil.fetchUtilFailed, (state, { error }) => ({ ...state, error, courts: [], loading: false })),
  on(fromUtil.setTypesCourts, (state, { typesCourt }) => ({ ...state, typesCourt })),
  on(fromUtil.setSportsCourts, (state, { sportsCourt }) => ({ ...state, sportsCourt })),
  on(fromUtil.setLoading, (state, { loading }) => ({ ...state, loading })),
  on(fromUtil.setShowloading, (state, { loading }) => ({ ...state, loading })),
);
