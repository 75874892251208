<ion-app>
  <ion-split-pane contentId="main-content" when="768">
    <ion-menu contentId="main-content" id="lessorMenuId">

      <ion-header class="menu-header">
        @if(isPWA()) {
          <div class="menu-header-bg-web"></div>
        } @else {
          <div class="menu-header-bg"></div>
        }

        <div class="header-content" [ngClass]="{ 'header-content-web':  isPWA()}">
          @if (!!selectSportCenterImage()) {
            <img style="border-radius: 53%;" class="preview-img" [src]="selectSportCenterImage()" width="40" />
          }
          <ion-label>
            @if (selectSportCenterName(); as selectSportCenterName) {
              <ion-row style="margin-left: -5px;">
                <ion-col size="10">
                  <h2>{{ selectSportCenterName | summarizeName }}</h2>
                </ion-col>
                <ion-col (click)="redirectPricingPage()" size="2">
                  <img class="icon-premium" src="assets/icon/arena-info/premium.svg" width="100" />
                </ion-col>
              </ion-row>
            }
            <p style="padding-bottom: 30px;">{{ selectResponsavelName() }}</p>
           
          </ion-label>
 
        </div>
      </ion-header>

      <ion-content class="ion-padding" [fullscreen]="true">

        @for (menu of sideMenuList(); track menu) {
          <ion-list class="menu-scroll" lines="none" class="menu-items">
            <ion-menu-toggle auto-hide="true" style="padding-top: 1px;">
              <ion-item [disabled]="menu.disabled" style="padding: 5px;" (click)="redirectTo(menu.url)" routerDirection="forward" lines="none">
                <img [src]="menu.icon" class="img-style">
                <span style="color: var(--ion-text-menu);" translate>{{menu.title}}</span>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
        }

      </ion-content>


      <ion-footer>
        @if (isDevelopment()) {
          <ion-toolbar style="text-align: center;" color="danger">
            {{ environmentTitle() }}
          </ion-toolbar>
        }
        <ion-toolbar style="text-align: center;">
          v{{ version }}
        </ion-toolbar>
        <ion-toolbar class="ion-padding" style="margin-top: -15px;">
          <ion-buttons slot="end">
            <ion-button (click)="logout()">
              <ion-icon name="log-out-outline" slot="end"></ion-icon>
              <span translate>LESSOR.SIDE_MENU.LOGOUT</span>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-footer>

    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>

  </ion-split-pane>
</ion-app>