import { SportCenterPolicy } from '@libs/entity-lib';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './policy-info.actions';

export interface State {
  loading: boolean;
  policy: SportCenterPolicy | null;
  error: any | null;
}

export const initialState: State = {
  loading: false,
  policy: null,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(fromActions.fetchPolicyInfo, (state) => ({ ...state, loading: true })),
  on(fromActions.fetchPolicyInfoSuccefully, (state, { sportCenterPolicy }) => ({ ...state, policy: sportCenterPolicy, loading: false })),
  on(fromActions.fetchPolicyInfoFailed, (state, { error }) => ({ ...state, error, loading: false })),

  on(fromActions.registerPolicyInfo, (state) => ({ ...state, loading: true })),
  on(fromActions.registerPolicyInfoSuccefully, (state, { sportCenterPolicy }) => ({ ...state, sportCenterPolicy, loading: false })),
  on(fromActions.registerPolicyInfoFailed, (state, { error }) => ({ ...state, error, loading: false })),
);
