
import { SocialMedia } from '@libs/entity-lib';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './social-media.actions';

export interface State {
  loading: boolean;
  socialMedia: SocialMedia | null;
  error: any | null;
}

export const initialState: State = {
  loading: false,
  socialMedia: null,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(fromActions.fetchSocialMedia, (state) => ({ ...state, loading: true })),
  on(fromActions.fetchSocialMediaSuccefully, (state, { socialMedia }) => ({ ...state, socialMedia, loading: false })),
  on(fromActions.fetchSocialMediaFailed, (state, { error }) => ({ ...state, error, loading: false })),
);
