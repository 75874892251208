import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'summarizeName',
  standalone: true,
})
export class SummarizeNamePipeComponent implements PipeTransform {
  transform(fullName: string): string {
    const parts = fullName.split(' ');
    if (parts.length >= 2) {
      return parts[0] + ' ' + parts[1];
    }
    return fullName;
  }
}
