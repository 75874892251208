
import { createReducer, on } from '@ngrx/store';
import { SearchAtletaResponse, Sport } from '@libs/entity-lib';
import * as fromSportCenter from './sport-center.actions';

export interface State {
  loading: boolean;
  sports: Sport[];
  searchAtleta: SearchAtletaResponse | null;
  error: any | null;
}

export const initialState: State = {
  loading: false,
  sports: [],
  searchAtleta: null,
  error: null
};

export const reducer = createReducer(
  initialState,
  on(fromSportCenter.fetchSportsByIdProvideCourtSuccess, (state, { sports }) => ({ ...state, sports })),
  on(fromSportCenter.fetchAtletaByCellphoneSuccess, (state, { searchAtleta }) => ({ ...state, searchAtleta })),
  on(fromSportCenter.postEventRentSuccess, (state,) => ({ ...state })),
  on(fromSportCenter.fetchFailedDefault, (state, { error }) => ({ ...state, error })),
  on(fromSportCenter.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  }))
);
