import { Injectable } from '@angular/core';

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {
  catchError, mergeMap,
  Observable, take,
  throwError
} from 'rxjs';
import { AuthFacade } from '@libs/auth-lib';
import { Router } from '@angular/router';
import { getRefreshTokenPath } from '@libs/entity-lib';


@Injectable()
export class InterceptorProvider implements HttpInterceptor {
  constructor(private router: Router, private authFacade: AuthFacade) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.authFacade.selectAccessToken$.pipe(
      take(1),
      mergeMap((token) => {
        if (token) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
        }
        const isAuthPath = request.url.toLowerCase().includes('signin');
        return next.handle(request).pipe(
          catchError((error) => {
            if ((error.status === 401 || error.status === 403) && !isAuthPath) {
              this.authFacade.setExpiredToken(true);
              this.router.navigate([getRefreshTokenPath()]);
            }
            return throwError(error);
          })
        );
      })
    );
  }
}
