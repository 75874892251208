
import { Customer } from '@libs/entity-lib';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as fromCustomer from './customer.actions';

export interface State extends EntityState<Customer> {
    loading: boolean;
    customers: Customer[] | null;
    error: any | null;
    first?: boolean;
    last?: boolean;
    currentPage: number;
    numberOfElements?: number;
    totalElements?: number;
    totalPages?: number;
}

const adapter: EntityAdapter<Customer> = createEntityAdapter<Customer>({
  selectId: (packageValue: Customer) => packageValue.id
});

export const initialState: State = adapter.getInitialState({
  loading: false,
  customers: null,
  error: null,
  currentPage: 0
});


export const reducer = createReducer(
  initialState,
  on(fromCustomer.fetchCustomers, (state) =>  ({ ...state, loading: true, error: null })),
  on(fromCustomer.fetchCustomersSuccessfuly, (state, { customerResponse }) => {
    const {
      first,
      last,
      number,
      numberOfElements,
      totalElements,
      totalPages,
      content,
    } = customerResponse;

    return adapter.upsertMany(content, {
      ...state,
      first,
      last,
      currentPage: number ?? 0,
      numberOfElements,
      totalElements,
      totalPages,
      loading: false
    })
  }),
  on(fromCustomer.fetchCustomersFailed, (state, { error }) =>  adapter.setAll([], { ...state, loading: false, error })),
  on(fromCustomer.setLoading, (state, { loading }) => ({ ...state, loading }))
);

export const { selectAll } = adapter.getSelectors();